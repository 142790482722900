import React from 'react'
import LinkedInLogo from '../images/linkedin-logo.png'
import GitHubLogo from '../images/github-logo.png'
import HackerRankLogo from '../images/hackerrank-logo.png'
import NintyNineDesignsLogo from '../images/99designs-logo.png'

const Menu = () => (
  <div
    style={{
      paddingTop: '10px',
      width: '75%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '15%',
    }}
  >
    <ul
      style={{
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-evenly',
        marginLeft: '0',
      }}
    >
      <li>
        <a
          href="https://www.linkedin.com/in/beakal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedInLogo} alt="LinkedIn" />
        </a>
      </li>
      <li>
        <a
          href="https://github.com/beakal-asefa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GitHubLogo} alt="GitHub" />
        </a>
      </li>
      <li>
        <a
          href="https://www.hackerrank.com/profile/beakal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={HackerRankLogo} alt="HackerRank" />
        </a>
      </li>
      <li>
        <a
          href="https://99designs.com/profiles/beakal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={NintyNineDesignsLogo} alt="99designs" />
        </a>
      </li>
    </ul>
  </div>
)

export default Menu
