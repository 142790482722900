import React from 'react'

const Header = ({ siteTitle }) => (
  <div 
    style={{
      marginBottom: '1.45rem',
    }}
  >
    

    
  </div>
)

export default Header
